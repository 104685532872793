@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  color: rgb(var(--foreground-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@layer base {
  @font-face {
    font-family: "Pretendard";
    font-weight: 700;
    src: url("./fonts/Pretendard-Bold.woff2") format("woff2");
    font-display: fallback;
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 300;
    src: url("./fonts/Pretendard-Light.woff2") format("woff2");
    font-display: fallback;
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 500;
    src: url("./fonts/Pretendard-Medium.woff2") format("woff2");
    font-display: fallback;
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 400;
    src: url("./fonts/Pretendard-Regular.woff2") format("woff2");
    font-display: fallback;
  }
}
